import React from 'react';
import s from './ChoiceTaskPage.module.css'

const ChoiceTaskPage = () => {
    return (
        <div>
            
        </div>
    );
};

export default ChoiceTaskPage;