export default class UserClass{

    id
    username
    password
    stringName
    role
    game
    type
    isActive
    description


    constructor() {
        this.id = 'new'
        this.username = '';
        this.password ='';
        this.stringName = '';
        this.role = '';
        this.game = '';
        this.type = '';
        this.isActive = true;
        this.description = ''

    }

}